import axios from "axios";
import { API_URL, ACCESS_TOKEN } from "../constants";

export default class Transactions {

  getTransactionHistory = (payload) => {
    const url = `${API_URL}api/v1/agents/transactions?trxType=${
      payload?.trxType || ""
    }&operation_type=${payload?.operationType || ""}&history_from=${
      payload?.historyFrom || ""
    }&currency=${payload?.currency || ""}&search=${payload?.search || ""}`;
    return axios
      .get(url, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  getLatestTransactionHistory = (payload) => {
    const url = `${API_URL}api/v1/agents/transactions/latest?trxType=${
      payload?.trxType || ""
    }&operation_type=${payload?.operationType || ""}&history_from=${
      payload?.historyFrom || ""
    }&currency=${payload?.currency || ""}`;
    return axios
      .get(url, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  
}
