/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, Suspense } from "react";
import { Layout, Space, Avatar, FloatButton } from "antd";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import Spinner from "../common/spinner";
import SiderBar from "./sidebar";
import FloatIcon from "../../images/floatIcon.svg"
import { setUserData } from "../../utils/redux/actions";
import "./styles.scss";

const Dashboard = React.lazy(() => import("../Dashboard"));
const MoneyIn = React.lazy(() => import("../MoneyIn"));
const CommissionLog = React.lazy(() =>import("../CommissionLog"));
const TransactionHistory = React.lazy(() =>import("../Transactions/TransactionHistory"));
const AddMoney = React.lazy(() => import("../AddMoney"));
const AddMoneyHistory = React.lazy(() => import("../AddMoneyHistory"));
const WithDrawMoney = React.lazy(() => import("../WithdrawMoney"));
const WithDrawHistory = React.lazy(() => import("../WithdrawHistory"));
const AddWithDrawMethod = React.lazy(() => import("../WithdrawMoney/AddWithdrawMethod"));
const ProfileSettings = React.lazy(() => import("../ProfileSetting"));
const ChangePassword = React.lazy(() => import("../ChangePassword"));
const TwoFactorsAuthentication = React.lazy(() =>import("../TwoFactorAuthentication"));
const MyQRCode = React.lazy(() => import("../MyQrCode"));
const BusinessApiKey = React.lazy(() => import("../BusinessApiKey"));
const SupportTicket = React.lazy(() => import("../SupportTicket"));
const AddnewTicket = React.lazy(() => import("../SupportTicket/AddnewTicket"));
const TicketDetail = React.lazy(() => import("../SupportTicket/TicketDetail"));
const LogoutDevices = React.lazy(() => import("../LogoutDevices"));
const KycSection = React.lazy(() => import("../KycSection"));
const ScreenLock = React.lazy(() => import("../ScreenLock"));
const ChatSystem = React.lazy(() => import("../chatSystem"));
const { Header, Content } = Layout;

const AdminLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [collapsed, setCollapsed] = useState(false);
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const { profileData } = useSelector((state) => {
    return state?.app;
  })

  const handleChangeLogout = () => {
    navigate("/admin/change-password");
    setOpen(false);
  };

  // const userPopover = () => {
  //   return (
  //     <section className="user-inner-container">
  //       <div className="profile-opt" onClick={handleProfileClick}>
  //         <Avatar size={20} src="https://joeschmoe.io/api/v1/random" />
  //         Profile
  //       </div>
  //       <div className="password-opt" onClick={handleChangeLogout}>
  //         <LoginOutlined />
  //         Change Password
  //       </div>
  //       <div className="logout-opt" onClick={handleLogout}>
  //         <LoginOutlined />
  //         Logout
  //       </div>
  //     </section>
  //   );
  // };

  const openMenu = () => {
    setOpen(!open);
  };

  // const handleProfileClick = () => {
  //   navigate(`/admin/profile`);
  //   setOpen(false);
  // };
 
  // const handleLogout = () => {
  //   localStorage.removeItem("agent-panel-data");
  //   dispatch(setUserData({}));
  //   navigate("/");
  //   setOpen(false);
  // };

  return (
    <>
      <Header className="site-layout-background bugify-navbar">
        <div className="merchant-name">{profileData?.first_name} {profileData?.last_name}</div>
        <Space align="center" size={20}>
          <div
            placement="rightBottom"          
            className="user-popover"
            open={open}           
          >
            <span  onClick={()=>navigate("/support-ticket")}>Support Ticket</span> &nbsp;&nbsp; | &nbsp;&nbsp;
            <Avatar onClick={()=>navigate("/profile-setting")} src={profileData?.image} /> &nbsp;
            <span className="admin-text" onClick={()=>navigate("/profile-setting")}>{profileData?.first_name} {profileData?.last_name}</span>
          </div>
        </Space>
      </Header>
      <FloatButton icon={<img src={FloatIcon} alt="#"/>} onClick={() =>navigate("/chat") } />
      <Layout>
        <SiderBar toggle={toggle} collapsed={collapsed} />
        <Layout className="site-layout">
          <Content className="site-layout-background content-main-section">
            <Suspense fallback={<Spinner />}>
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/money-in" element={<MoneyIn />} />
                <Route path="/transaction-history" element={<TransactionHistory />}                />
                <Route path="/commission-log" element={<CommissionLog />} />
                <Route path="/add-money" element={<AddMoney />} />
                <Route path="/addmoney-history" element={<AddMoneyHistory />} />
                <Route path="/withdraw-money" element={<WithDrawMoney />} />
                <Route path="/add-withdraw-method" element={<AddWithDrawMethod />} />
                <Route path="/withdraw-history" element={<WithDrawHistory />} />
                <Route path="/profile-setting" element={<ProfileSettings />} />
                <Route path="/change-password" element={<ChangePassword />} />
                <Route path="/2fa-authentication" element={<TwoFactorsAuthentication />}/>
                <Route path="/my-qrcode" element={<MyQRCode />} />
                <Route path="/business-api" element={<BusinessApiKey />} />
                <Route path="/support-ticket" element={<SupportTicket />} />
                <Route path="/add-support-ticket" element={<AddnewTicket />} />
                <Route path="/ticket-detail/:ticketId" element={<TicketDetail />} />
                <Route path="/logout-options" element={<LogoutDevices />} />
                <Route path="/kyc" element={<KycSection />} />
                <Route path="/lock-screen" element={<ScreenLock />} />
                <Route path="/chat" element={<ChatSystem />} />
                {/*  */}
              </Routes>
            </Suspense>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default AdminLayout;
