import Auth from "./Auth";
import MoneyIn from "./MoneyIn";
import Notifications from "./Notifications";
import Banner from "./Banner";
import Messages from "./Messages";
import Transactions from "./Transactions";
import GlobalSettings from "./GlobalSettings";
import FixedPage from "./FixedPage";
import Donations from "./Donations";
import Info from "./Info";
import Payments from "./Payments";
import Withdraws from "./Withdraws";
import Dashboard from "./Dashboard";
import Profile from "./Profile";
import QRCode from "./QRcode";
import Support from "./Support";
import Kyc from "./Kyc";
const API = {
  Auth,  
  
  Notifications,
  Banner,
  Messages,  
  GlobalSettings,
  FixedPage,
  Donations,
  Info,
  //
  Payments,
  Withdraws,
  Dashboard,
  MoneyIn,
  Transactions,
  Profile,
  QRCode,
  Support,
  Kyc
};

export default API;
