import { createAction } from "redux-actions";

export const setUserData = createAction(
  "SET_USER_DATA",
  (userData) => userData
);
export const setAgentWallets = createAction(
  "SET_AGENT_WALLETS",
  (agentWallets) => agentWallets
);
export const setProfileData = createAction(
  "SET_PROFILE_DATA",
  (profileData) => profileData
);
export const setShowLockScreen = createAction(
  "SET_SHOW_LOCK",
  (showLockScreen) => showLockScreen
);