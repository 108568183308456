import axios from "axios";
import { API_URL, ACCESS_TOKEN } from "../constants";

export default class Payments {
  makePayment = (formData) => {
    const url = `${API_URL}api/v1/users/make-payment`;
    return axios({
      url,
      method: "POST",
      data: formData,
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  moneyOut = (formData) => {
    const url = `${API_URL}api/v1/users/money-out`;
    return axios({
      url,
      method: "POST",
      data: formData,
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  getaddMoneyLogs = () => {
    return axios
      .get(`${API_URL}api/v1/agents/add-money/history`, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  getpaymentGateways = () => {
    return axios
      .get(`${API_URL}api/v1/agents/gateway-currencies`, {
        headers: {
          Authorization: `Token ${ACCESS_TOKEN}`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
  addMoney = (formData) => {
    const url = `${API_URL}api/v1/agents/add-money/initiate/${formData?.gateway_currency_id}`;
    return axios({
      url,
      method: "POST",
      data: formData,
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
  addManualReceipt = (
    formData,
    { manual_gateway_id, manual_gateway_currency, diposit_id }
  ) => {
    const url = `${API_URL}api/v1/agents/add-money/manual-diposit-confirm/${manual_gateway_id}/${manual_gateway_currency}/${diposit_id}`;
    return axios({
      url,
      method: "POST",
      data: formData,
      headers: {
        Authorization: `Token ${ACCESS_TOKEN}`,
      },
    })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error.response?.data;
      });
  };
}
