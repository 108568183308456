import { React, useState, useEffect } from "react";
import { Layout, Menu } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserData } from "../../../utils/redux/actions";
import LogoImg from "../../../images/wallet-logo-pngdark 1.svg";
import HomeIcon from "../../../images/ChartPieSlice.svg";
import MoneyInIcon from "../../../images/MoneyIn.svg";
import TransactiontIcon from "../../../images/ShoppingBagOpen-d.svg";
import addmoneyIcon from "../../../images/Money-r.svg"
import WithdrawtIcon from "../../../images/withdraw money.svg";
import historyIcon from "../../../images/history.svg";
import profileIcon from "../../../images/IdentificationBadge-d.svg";
import passwordIcon from "../../../images/IdentificationCard-d.svg";
import securityIcon from "../../../images/UsersThree-d.svg";
import qrIcon from "../../../images/QR.png";
import ApiIcon from "../../../images/ChatsTeardrop-d.svg";
import logoutIcon from "../../../images/logout.svg";
import KycIcon from "../../../images/kyc.svg"

import "./styles.scss";

const { Sider } = Layout;

const SiderBar = ({ toggle }) => {
  const rootSubmenuKeys = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openKeys, setOpenKeys] = useState([""]);

  const handleLogout = () => {
    localStorage.removeItem("agent-panel-data");
    dispatch(setUserData({}));
    navigate("/");
  };

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  return (
    <Sider collapsible onCollapse={toggle}>
      <div className="laststand-logo">
        <img className="collapsed-img" src={LogoImg} alt="" />
      </div>
      <Menu
        defaultSelectedKeys={["1"]}
        mode="inline"
        onOpenChange={onOpenChange}
        onClick={() => setOpenKeys([""])}
        openKeys={openKeys}
      >
        <Menu.Item
          key="1"
          onClick={() => navigate("/")}
          icon={<img src={HomeIcon} alt="" />}
        >
          Dashboard
        </Menu.Item>
        <Menu.Item
          key="2"
          onClick={() => navigate("/money-in")}
          icon={<img src={MoneyInIcon} alt="" />}
        >
          Money In
        </Menu.Item>
        <Menu.Item
          key="3"
          onClick={() => navigate("/transaction-history")}
          icon={<img src={TransactiontIcon} alt="" />}
        >
          Transactions
        </Menu.Item>
        <Menu.Item
          key="4"
          onClick={() => navigate("/commission-log")}
          icon={<img src={historyIcon} alt="" />}
        >
          Commission Log
        </Menu.Item>
        <div className="divider" />
        <Menu.Item
          key="5"
          onClick={() => navigate("/add-money")}
          icon={<img src={addmoneyIcon} alt="" />}
        >
          Add Money
        </Menu.Item>
        <Menu.Item
          key="6"
          onClick={() => navigate("/addmoney-history")}
          icon={<img src={historyIcon} alt="" />}
        >
         Add Money History
        </Menu.Item>
        <div className="divider" />
        <Menu.Item
          key="7"
          onClick={() => navigate("/withdraw-money")}
          icon={<img src={WithdrawtIcon} alt="" />}
        >
          Withdraw Money
        </Menu.Item>
        <Menu.Item
          key="8"
          onClick={() => navigate("/withdraw-history")}
          icon={<img src={historyIcon} alt="" />}
        >
          Withdraw History
        </Menu.Item>
        <div className="divider" />
        <Menu.Item
          key="14"
          onClick={() => navigate("/kyc")}
          icon={<img src={KycIcon} alt="" />}
        >
          KYC
        </Menu.Item>
        <Menu.Item
          key="9"
          onClick={() => navigate("/profile-setting")}
          icon={<img src={profileIcon} alt="" />}
        >
          Profile Setting
        </Menu.Item>
        <Menu.Item
          key="10"
          onClick={() => navigate("/change-password")}
          icon={<img src={passwordIcon} alt="" />}
        >
          Password Setting
        </Menu.Item>
        <Menu.Item
          key="11"
          onClick={() => navigate("/2fa-authentication")}
          icon={<img src={securityIcon} alt="" />}
        >
          2FA Security
        </Menu.Item>
        <Menu.Item
          key="12"
          onClick={() => navigate("/my-qrcode")}
          icon={<img src={qrIcon} alt="" />}
        >
          My QR Code
        </Menu.Item>
       
        <Menu.Item
          key="13"
          onClick={() => handleLogout()}
          icon={<img src={logoutIcon} alt="" />}
        >
          Logout
        </Menu.Item>

        <br />
        <br />
        <br />
        <br />
      </Menu>
    </Sider>
  );
};

export default SiderBar;
