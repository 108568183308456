import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { Form, Input, Button, message,Typography,Card } from "antd";
import { useDispatch } from "react-redux";
import { setUserData } from "../../utils/redux/actions";
import API from "../../utils/api";
import { getDataManager, getErrorMessage } from "../../utils/helper.functions";
import Spinner from "../common/spinner";
import screenLockImg from "../../images/screenlock.png";
import "./styles.scss";
const ScreenLock = () => {
  const [form] = Form.useForm();

  const { Title } = Typography;
  const dispatch = useDispatch();

  const unlock = new API.Auth();
  const [loading, setLoading] = useState(false);
  const [is2fa, setIs2fa] = useState(false);
  const [otp, setOtp] = useState();
  const [userId, setUserId] = useState();

  const handleRefresh = () => {
    window.location.reload(true);
  };

  const onLogin = (payload) => {
    getDataManager(unlock?.loginUser, setLoading, payload).then((x) => {
      if (x?.status === true) {
        if (x?.data?.is_2fa_enabled === false) {
          message.success({
            content: "You have successfully logged in",
            duration: 3,
          });
          localStorage.setItem("agent-panel-data", JSON.stringify(x?.data));
          dispatch(setUserData(x?.data));
          // navigate("/");
          handleRefresh();
        } else {
          setIs2fa(true);
          setUserId(x?.data?.user_id);
        }
      } else {
        const error = getErrorMessage(x?.errors);
        localStorage.removeItem("agent-panel-data");
        message.error({
          content: x?.message || error || "Error ocurred while logging in",
          duration: 3,
        });
      }
    });
  };
  const handleVerification = () => {
    if (otp?.length === 6) {
      const payload = {
        token: otp,
        user_id: userId,
      };
      getDataManager(unlock?.validate2FaSecurity, setLoading, payload).then(
        (x) => {
          if (x?.status === true) {
            message.success({
              content: "You have successfully logged in",
              duration: 3,
            });
            localStorage.setItem(
              "agent-panel-data",
              JSON.stringify(x?.data)
            );
            console.log(x?.data);
            dispatch(setUserData(x?.data));
            handleRefresh();
          } else {
            const error = getErrorMessage(x?.errors);
            message.error({
              content: x?.message || error || "Error ocurred while sending otp",
              duration: 3,
            });
          }
        }
      );
    }
  };

  return (
    <section className="screen-lock-section">
      {!is2fa ? (
        <section className="screen-unlock">
          <div className="left-section">
            <div className="heading-text">Welcome Back</div>
            <Form
              form={form}
              onFinish={onLogin}
              scrollToFirstError
              layout="vertical"
            >
              <Form.Item name="email">
                <Input
                  placeholder="Username"
                  size="large"
                  className="form-input"
                />
              </Form.Item>
              <Form.Item name="password" className="password-field">
                <Input
                  placeholder="Password"
                  visible={true}
                  size="large"
                  type="password"
                  className="form-input"
                  //   iconRender={(visible) =>
                  //     visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  //   }
                />
              </Form.Item>

              <Form.Item>
                <Button
                  htmlType="submit"
                  block
                  type="primary"
                  className="login-submit-button"
                >
                  Unlock
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div className="right-section">
            <img src={screenLockImg} alt="#" />
          </div>
        </section>
      ) : (
        <Card className="login-card" bordered={false}>
          {loading && <Spinner />}
          <Title level={4} className="login-title">
            2 FA Authentication
          </Title>
          <Form
            form={form}
            layout="vertical"
            // onFinish={()=>handleVerification()}
            scrollToFirstError
          >
            <Form.Item label="Otp Code">
              <OtpInput
                numInputs={6}
                value={otp}
                onChange={(e) => setOtp(e)}
                containerStyle="verification-code-container"
                inputStyle="one-digit-input"
              />
            </Form.Item>

            <Form.Item>
              <Button
                // htmlType="submit"
                size="large"
                type="primary"
                className="forget-submit-button"
                onClick={() => handleVerification()}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Card>
      )}
    </section>
  );
};

export default ScreenLock;
